import { useMutation } from "@tanstack/react-query";
import { Button } from "antd";
import { importApi } from "apis";
import dayjs from "dayjs";

export default function ExportButton({
  buttonContent = "Xuất dữ liệu",
  type = "",
  config = { responseType: "blob" },
  fileName,
  addTimeToName = true,
  fileExtension = ".xlsx",
  ...props
}) {
  const downloadFile = useMutation(importApi.exportFile, {
    onSuccess: response => {
      const currentTime = dayjs().format("DD_MM_YYYY_HH_mm_ss");
      let renameFile = "file";

      const contentDispositionHeader = response.headers["content-disposition"];
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const fileNameMatches = fileNameRegex.exec(contentDispositionHeader);

      if (fileName) {
        renameFile = `${fileName}${
          addTimeToName ? currentTime : ""
        }${fileExtension}`;
      } else if (fileNameMatches != null && fileNameMatches[1]) {
        renameFile = decodeURIComponent(
          fileNameMatches[1].replace(/['"]/g, "")
        );
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", renameFile);
      document.body.appendChild(link);
      link.click();
    },
    onError: error => {
      console.log("err", error);
    },
  });

  return (
    <Button
      onClick={() => {
        downloadFile.mutate(type, config);
      }}
      loading={downloadFile.isLoading}
      {...props}
    >
      {buttonContent}
    </Button>
  );
}
