import { App as AntApp, ConfigProvider } from "antd";
import useNav from "hooks/useNav";
import { Suspense, lazy, useEffect } from "react";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { Loading, PrivateRoute } from "shared_components";
import "./App.css";

const DefaultLayout = lazy(() => import("layouts/DefaultLayout"));
const Login = lazy(() => import("containers/Login"));

function App() {
  const navs = useNav(true);
  const renderRoutes = () => {
    return navs.map(nav => (
      <Route
        key={nav.key}
        path={nav.key}
        element={<PrivateRoute>{nav.component()}</PrivateRoute>}
      />
    ));
  };

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#256532",
            colorText: "#256532",
          },
          components: {
            Menu: {
              itemSelectedColor: "#256532",
              itemHoverColor: "#256532",
            },
            Input: {
              colorBorder: "#256532",
            },
            Select: {
              colorBorder: "#256532",
            },
            Button: {
              colorBorder: "#256532",
            },
          },
        }}
      >
        <AntApp>
          <Suspense fallback={<Loading />}>
            <Router>
              <Routes>
                <Route path="/" element={<ContainerLayout />}>
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <DefaultLayout />
                      </PrivateRoute>
                    }
                  >
                    {renderRoutes()}
                  </Route>
                  <Route exact path="login" element={<Login />} />
                  <Route path="*" element={<NotFound />} />
                </Route>
              </Routes>
            </Router>
          </Suspense>
        </AntApp>
      </ConfigProvider>
    </div>
  );
}

function ContainerLayout() {
  const navigate = useNavigate();
  const match = useMatch("/");

  useEffect(() => {
    let access_token =
      localStorage.USER && JSON.parse(localStorage.USER)?.access_token;
    if (!access_token) navigate("/login", { replace: true });
    else {
      if (match) {
        navigate("/code-management", { replace: true });
      }
    }
  }, [match, navigate]);

  return (
    <div className="container">
      <Outlet />
    </div>
  );
}

function NotFound() {
  return <div>Không tìm thấy trang.</div>;
}

export default App;
