import { lazy } from "react";
import {
  BarcodeOutlined,
  // DashboardOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { Link } from "react-router-dom";

// const Dashboard = lazy(() => import("containers/Dashboard"));
const CodeManagement = lazy(() => import("containers/CodeManagement"));
const MasterImport = lazy(() => import("containers/MasterImport"));

const _nav = [
  // {
  //   key: "/dashboard",
  //   label: (
  //     <Link to="/dashboard" rel="noreferrer noopener">
  //       Dashboard
  //     </Link>
  //   ),
  //   title: "Dashboard",
  //   icon: <DashboardOutlined />,
  //   component: props => <Dashboard {...props} />,
  //   action_key: "VIEW_DASHBOARD",
  //   display: 1,
  // },
  {
    key: "/code-management",
    label: (
      <Link to="/code-management" rel="noreferrer noopener">
        Quản lý mã dự thưởng
      </Link>
    ),
    title: "Quản lý mã dự thưởng",
    icon: <BarcodeOutlined />,
    component: props => <CodeManagement {...props} />,
    display: 1,
  },
  {
    key: "/master-import",
    label: (
      <Link to="/master-import" rel="noreferrer noopener">
        Master Import
      </Link>
    ),
    title: "Master Import",
    icon: <UploadOutlined />,
    component: props => <MasterImport {...props} />,
    display: 1,
  },
];

export const getNavigation = ({
  navs = _.cloneDeep(_nav),
  isRoute = false,
} = {}) => {
  return navs.reduce((result, nav, index) => {
    let { children } = nav;
    let isDisplayForNav = !!nav.display; // display in left navigation
    if (isDisplayForNav || isRoute) result.push(nav);
    if (children?.length > 0) {
      let childNavs = getNavigation({ navs: children, isRoute }) ?? []; // recursive children
      if (isRoute) {
        if (!nav.component) result.splice(result.length - 1);
        result = [...result, ...childNavs];
      } else {
        if (childNavs.length > 0) result[index].children = [...childNavs];
        else delete result[index].children;
      }
    }
    return result;
  }, []);
};

export default _nav;
