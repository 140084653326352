import axiosClient from "./axiosClient";

const authUrl = `${process.env.REACT_APP_AUTH_API}/`;

const lotteryApi = {
  getLotteryCode(params) {
    const url = authUrl + "lottery-codes";
    return axiosClient.get(url, { params });
  },
  exportLotteryCode(params) {
    const url = authUrl + "lottery-codes/export";
    return axiosClient.get(url, { params });
  },
};

export default lotteryApi;
