import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    info: JSON.parse(localStorage.getItem("USER")) ?? undefined,
  },
  reducers: {
    login: (state, action) => {
      let info = {
        ...action?.payload?.info,
        expired_at: moment()
          .add(action?.payload?.info?.expires_in * 1, "seconds")
          .unix(),
      };
      state.info = info;
      localStorage.setItem("USER", JSON.stringify(info));
      return state;
    },
    logout: state => {
      state.info = undefined;
      localStorage.removeItem("USER");
      return state;
    },
  },
});

const { reducer } = authSlice;
export const { login, logout } = authSlice.actions;
export default reducer;
