import axios from "axios";
import axiosClient from "./axiosClient";

const authUrl = `${process.env.REACT_APP_AUTH_API}/auth/`;

const authApi = {
  login(data) {
    const url = authUrl + "token";
    return axiosClient.post(url, data);
  },
  logout(data) {
    const url = authUrl + "revoke-token";
    return axiosClient.post(url, data);
  },
  async refreshToken(data) {
    const url = authUrl + "refresh-token";
    return axios.post(url, data);
  },
};

export default authApi;
