import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { App, Card, Spin, Upload, theme } from "antd";
import { importApi } from "apis";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 48,
    }}
    spin
  />
);
const accpetType = [
  // "application/vnd.ms-excel", // .xls, .xml, .xlt
  // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  // "application/wps-office.xlsx",
  // "application/wps-office.xls",
  // "application/vnd.ms-excel.sheet.macroenabled.12", // .xlsm
  // "application/vnd.ms-excel.template.macroenabled.12", // .xltm
  // "application/vnd.ms-excel.sheet.binary.macroenabled.12", // .xlsb
  // "application/vnd.oasis.opendocument.spreadsheet", // .ods
  "text/csv",
  "text/plain",
];

export default function ImportData({
  title,
  configCard = {},
  fileName = "formFile",
  api = null,
  configUpload = {},
  config = {
    headers: {
      "Content-Type": "blob",
    },
  },
}) {
  const { token: antToken } = theme.useToken();
  const { message } = App.useApp();

  const uploadFile = useMutation(importApi[api], {
    onSuccess: () => {
      message.success("Import dữ liệu thành công");
    },
    onError: () => {
      message.error("Import dữ liệu thất bại");
    },
  });

  return (
    <Card title={title} {...configCard}>
      <Upload.Dragger
        disabled={uploadFile.isLoading}
        name={fileName}
        showUploadList={false}
        beforeUpload={file => {
          const isAccpet = accpetType.includes(file.type);

          if (!isAccpet) {
            message.error("File không đúng định dạng");
          }

          return isAccpet || Upload.LIST_IGNORE;
        }}
        customRequest={options => {
          const { file } = options;
          const data = new FormData();
          data.append(fileName, file);

          uploadFile.mutate(data, config);
        }}
        {...configUpload}
      >
        <p className="ant-upload-drag-icon">
          {uploadFile.isLoading ? (
            <Spin indicator={antIcon} />
          ) : (
            <InboxOutlined />
          )}
        </p>
        <p className="ant-upload-text">
          Nhấp chuột hoặc kéo file cần import vào vùng xám
        </p>
        <p style={{ color: antToken.colorError, marginBottom: 0 }}>
          Nội dung import phải theo đúng template
        </p>
        <p style={{ color: antToken.colorError, marginBottom: 0 }}>
          Các trường thông tin trong file import không được để trống, nếu trường
          nào không có thông tin thì người dùng có thể nhập Unknown
        </p>
      </Upload.Dragger>
    </Card>
  );
}
