import axiosClient from "./axiosClient";

const authUrl = `${process.env.REACT_APP_AUTH_API}/import`;

const importApi = {
  generateCode(data, config) {
    const url = authUrl + "/generate-codes";
    return axiosClient.post(url, data, config);
  },
  lotteryCode(data, config) {
    const url = authUrl + "/lottery-codes";
    return axiosClient.post(url, data, config);
  },
  exportFile(type, config) {
    const url = authUrl + "/export-template" + `?Type=${type}`;
    return axiosClient.post(url, {}, config);
  },
  listExportFile(params) {
    const url = process.env.REACT_APP_AUTH_API + "/media-files";
    return axiosClient.get(url, { params });
  },
};

export default importApi;
